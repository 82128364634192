import React, { useContext, useEffect, useState } from "react";

import SearchBar from "../../components/SearchBar/SearchBar";
import Carousel from "../../components/Carousel/Carousel";

import { useDispatch } from "react-redux";
import { cleanSearch } from "../../actions/searchActions";

import Axios from "../../services/Api";
import app_params from "../../config/params";
import NewScreen from "../NewScreen/NewScreen";
import { getFeaturedTitlesAPI } from "../../services/api/CustomOpacAssetsAPI";
import { OpacSettingContext } from "../../context/opacSettingContext";
// import { library_info } from "../../data/homeInfo";

const HomeScreen = (props) => {
  const [lastAcquisitions, setLastAcquisitions] = useState({
    records: [],
    hasDetailPage: false,
  });
  const [visitCount, setVisitCount] = useState(0);
  const dispatch = useDispatch();
  const [featuredTitles, setFeaturedTitles] = useState([]);
  const [loadingState, setLoadingState] = useState({});

  useEffect(() => {
    dispatch(cleanSearch());
  }, [dispatch]);

  useEffect(() => {
    Axios.GetLatestAcquisitions()
      .then((res) => res.data)
      .then((data) =>
        setLastAcquisitions(
          data?.success ? data : { records: [], hasDetailPage: false }
        )
      )
      .then(() => setLoadingState((prev) => ({ ...prev, adquisitions: true })))
      .catch((e) => {
        console.log(e);
        setLastAcquisitions({ records: [], hasDetailPage: false });
      });

    Axios.GetVisits()
      .then((res) => res.data)
      .then((data) => setVisitCount(data?.success ? data?.count : 0))
      .catch((e) => {
        console.log(e);
        setVisitCount(0);
      });

    const getFeaturedTitles = async () => {
      const response = await getFeaturedTitlesAPI();
      if (response?.success) setFeaturedTitles(response?.content);
      setLoadingState((prev) => ({ ...prev, featured: true }));
    };
    if (featuredTitles?.length < 1) getFeaturedTitles();
  }, []);

  useEffect(() => {
    if (
      loadingState?.featured &&
      loadingState?.news &&
      loadingState?.adquisitions
    ) {
      if (window.location.hash.startsWith("#")) {
        let id = window.location.hash.replace("#", "");
        var elemento = document.getElementById(id);
        if (elemento) {
          elemento.scrollIntoView();
        }
      }
    }
  }, [loadingState]);

  return (
    <OpacSettingContext.Provider value={{ setLoadingState }}>
      <section className="container">
        <div className="bg-white mx-0 mx-md-3 my-3 px-3 px-md-4 px-xl-5 py-5 shadow-sm rounded">
          <div className="row col-12 d-flex justify-content-center mb-2">
            <div className="col-12 col-lg-8 col-xl-6">
              <form action={`${app_params.basepath}/query`}>
                <SearchBar />
              </form>
            </div>
          </div>
          <div className="text-center">
            <a href={`${app_params.basepath}/advancedsearch`}>
              Búsqueda avanzada
            </a>
          </div>
        </div>
        {lastAcquisitions?.loading && (
          <div className="bg-white mx-0 mx-md-3 my-3 px-3 px-md-4 px-xl-5 py-5 shadow-sm rounded">
            <div className="col-12 p-3 p-lg-4 bg-white text-center">
              <i className="fa fa-spinner fa-spin"></i> Cargando
            </div>
          </div>
        )}
        {!lastAcquisitions?.loading && (
          <Carousel
            title={"Novedades"}
            items={lastAcquisitions?.records}
            hasDetailPage={lastAcquisitions?.hasDetailPage}
          />
        )}
        {featuredTitles?.map((category, idx) => {
          return (
            <div key={idx}>
              {category?.records?.length > 0 && (
                <Carousel
                  title={category?.name}
                  items={category?.records}
                  hasDetailPage={false}
                />
              )}
            </div>
          );
        })}
        {/* <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div
          className="row p-2"
          dangerouslySetInnerHTML={{ __html: library_info.description }}
        ></div>
      </div> */}
        <NewScreen />
        <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
          <div className="row p-2">
            <div className="col-12 text-center">
              Usted es el visitante # {visitCount}
            </div>
          </div>
        </div>
      </section>
    </OpacSettingContext.Provider>
  );
};

export default HomeScreen;
