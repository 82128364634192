import React from "react";

const DropDownInput = (props) => {
  return (
    <select className="form-select" id={props.name} name={props.name} onChange={props?.onChange}>
      {props.options.map((option) => (
        <option
          value={option.value}
          key={option.value}
        >
          {option.displayText}
        </option>
      ))}
    </select>
  );
};

export default DropDownInput;
