import {
	SEARCH_REQUEST,
	SEARCH_SUCCESS,
	SEARCH_FAIL,
	CLEAN_SEARCH,
} from '../constants/searchConstants';

import Axios from '../services/Api.js';

export const simpleSearch = (params) => async (dispatch) => {
	dispatch({ type: SEARCH_REQUEST });
	try {
		const { data } = await Axios.SimpleSearch({ params });
		data?.success === 'true'
			? dispatch({
					type: SEARCH_SUCCESS,
					payload: {
						result_header: data?.result_header,
						records: data?.records?.record,
					},
			  })
			: dispatch({
					type: SEARCH_FAIL,
					error: 'Problema al realizar la búsqueda, inténtelo mas tarde',
			  });
	} catch (error) {
		console.log(error);
		dispatch({
			type: SEARCH_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const cleanSearch = () => async (dispatch) => {
	dispatch({ type: CLEAN_SEARCH });
};
