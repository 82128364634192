import { useContext, useEffect, useState } from "react";
import { getNewsAPI } from "../../services/api/CustomOpacAssetsAPI";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import ImageWithFallback from "../../components/ImageWithFallback/ImageWithFallback";
import { OpacSettingContext } from "../../context/opacSettingContext";
import app_params from "../../config/params";
import "./NewScreen.css";
import ShareModal from "../../components/ShareModal/ShareModal";

const NewScreen = () => {
  const [loaded, setLoaded] = useState(false);
  const [news, setNews] = useState([]);
  const { setLoadingState } = useContext(OpacSettingContext);

  useEffect(() => {
    const getNews = async () => {
      const response = await getNewsAPI();
      setLoaded(true);
      if (response?.success) setNews(response?.content);
      setLoadingState((prev) => ({ ...prev, news: true }));
    };
    if (!loaded) getNews();
  }, [loaded]);

  if (!loaded) {
    return (
      <>
        <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded text-center">
          <LoadingBox />
        </div>
      </>
    );
  }

  if (loaded && news?.length < 1) {
    return (
      <>
        <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
          <p>No hay noticias disponibles.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded text-center mb-0"
        id="news"
      >
        <h4>Noticias</h4>
      </div>
      {news?.map((newItem, idx) => {
        const coverUrl = newItem?.cover
          ? newItem.cover
          : `${app_params.basepath}/assets/default-new-image.jpg`;
        return (
          <div
            key={idx}
            className="row bg-white mx-0 mx-md-3 my-3 py-4 shadow-sm rounded"
            id={`new_${newItem?.id}`}
          >
            <div className="col-12 col-lg-4">
              <div className="row text-lg-center cover_container">
                <a
                  href={coverUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="p-0"
                  style={{ margin: 0 }}
                >
                  <ImageWithFallback
                    fallback={`${app_params.basepath}/assets/default-new-image.jpg`}
                    src={newItem?.cover}
                    className={`figure-img img-fluid rounded border border-1 cover_thumb rounded mx-auto d-block`}
                  />
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-8 mb-0">
              <div className="d-flex justify-content-end  mobile_center">
                <button
                  className="btn btn-sm btn-outline-secondary mb-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#shareModal-news-${newItem?.id}`}
                >
                  <span>
                    <i className="fas fa-share-alt"></i> Compartir
                  </span>
                </button>
              </div>
              <div className="row">
                <div className="text-center">
                  <h4>{newItem?.title}</h4>
                </div>
                <div dangerouslySetInnerHTML={{ __html: newItem?.content }} />
              </div>
            </div>
            <p className="text-end fw-light fst-italic py-0 mb-0">
              {newItem?.created_at} - {newItem?.news_type}
            </p>
            <ShareModal
              id={`shareModal-news-${newItem?.id}`}
              url={`${window.location.origin}${app_params.basepath}/#new_${newItem?.id}`}
              quote={newItem?.title}
            />
          </div>
        );
      })}
    </>
  );
};

export default NewScreen;
