import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  LineIcon,
} from "react-share";
import Toast from "../Toast/Toast";

const ShareModal = (props) => {
  const shareRecord = () => {
    navigator.clipboard.writeText(props.url);
  };

  return (
    <div className="modal fade" id={props.id} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Compartir</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <FacebookShareButton
                url={props.url}
                quote={props.quote}
                className="m-1"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={props.url}
                title={props.quote}
                className="m-1"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={props.url}
                title={props.quote}
                className="m-1"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <TelegramShareButton
                url={props.url}
                title={props.quote}
                className="m-1"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <LineShareButton
                url={props.url}
                title={props.quote}
                className="m-1"
              >
                <LineIcon size={32} round />
              </LineShareButton>
              <button
                className="btn btn-secondary btn-sm rounded-circle m-1"
                id={`shareBtn-${props.id}`}
                onClick={() => shareRecord()}
              >
                <span>
                  <i className="fas fa-link"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toast
        buttonID={`shareBtn-${props.id}`}
        toastID={`shareToast-${props.id}`}
        variant="secondary"
      >
        Enlace copiado en portapapeles
      </Toast>
    </div>
  );
};

export default ShareModal;
