import React, { useEffect, useState } from "react";

import SearchBar from "../../components/SearchBar/SearchBar";
import Carousel from "../../components/Carousel/Carousel";

import { useDispatch } from "react-redux";
import { cleanSearch } from "../../actions/searchActions";

import Axios from "../../services/Api";
import app_params from "../../config/params";
import { getFeaturedTitlesAPI } from "../../services/api/CustomOpacAssetsAPI";
import NewScreen from "../NewScreen/NewScreen";

const HomeScreen = (props) => {
  const [lastAcquisitions, setLastAcquisitions] = useState({
    records: [],
    hasDetailPage: false,
    loading: true,
  });
  // const [visitCount, setVisitCount] = useState(0);
  const [featuredTitles, setFeaturedTitles] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanSearch());
  }, [dispatch]);

  useEffect(() => {
    setLastAcquisitions({ ...lastAcquisitions, loading: true });
    Axios.GetLatestAcquisitions()
      .then((res) => res.data)
      .then((data) =>
        setLastAcquisitions(
          data?.success
            ? data
            : { records: [], hasDetailPage: false, loading: false }
        )
      )
      .catch((e) => {
        console.log(e);
        setLastAcquisitions({
          records: [],
          hasDetailPage: false,
          loading: false,
        });
      });

    const getFeaturedTitles = async () => {
      const response = await getFeaturedTitlesAPI();
      if (response?.success) setFeaturedTitles(response?.content);
    };
    if (featuredTitles?.length < 1) getFeaturedTitles();

    // const getNews = async () =>{
    //   const response = await getNewsAPI();
    //   if (response?.success) setNews(response?.content);
    // }
    // if (news?.length < 1) getNews();

    // Axios.GetVisits()
    //   .then((res) => res.data)
    //   .then((data) => setVisitCount(data?.success ? data?.count : 0))
    //   .catch((e) => {
    //     console.log(e);
    //     setVisitCount(0);
    //   });
  }, []);

  return (
    <section className="container">
      <div className="bg-white mx-0 mx-md-3 my-3 px-3 px-md-4 px-xl-5 py-5 shadow-sm rounded">
        <div className="row col-12 d-flex justify-content-center mb-2">
          <div className="col-12 col-lg-8 col-xl-6">
            <form action={`${app_params.basepath}/query`}>
              <SearchBar />
            </form>
          </div>
        </div>
        <div className="text-center">
          <a href={`${app_params.basepath}/advancedsearch`}>
            Búsqueda avanzada
          </a>
        </div>
      </div>
      {lastAcquisitions?.loading && (
        <div className="bg-white mx-0 mx-md-3 my-3 px-3 px-md-4 px-xl-5 py-5 shadow-sm rounded">
          <div className="col-12 p-3 p-lg-4 bg-white text-center">
            <i className="fa fa-spinner fa-spin"></i> Cargando
          </div>
        </div>
      )}
      {!lastAcquisitions?.loading && (
        <Carousel
          title={"Novedades"}
          items={lastAcquisitions?.records}
          hasDetailPage={lastAcquisitions?.hasDetailPage}
        />
      )}
      {featuredTitles?.map((category) => {
        if (category?.records?.length < 1) {
          return <></>;
        }
        return (
          <Carousel
            title={category?.name}
            items={category?.records}
            hasDetailPage={false}
          />
        );
      })}
      {/* <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div
          className="row p-2"
          dangerouslySetInnerHTML={{ __html: library_info.description }}
        ></div>
      </div> */}
      {/* <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div className="row p-2">
          <div className="col-12 text-center">
            Usted es el visitante # {visitCount}
          </div>
        </div>
      </div> */}
      <NewScreen />
    </section>
  );
};

export default HomeScreen;
