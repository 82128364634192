import axios from 'axios';
import ApiURLs from '../config/ApiURLs.js';

class Service {
	constructor() {
		let headers = {};

		let userLocal = localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null;

		if (userLocal && userLocal.access_token) {
			headers.Authorization = `Bearer ${userLocal.access_token}`;
		}
		let service = axios.create({
			baseURL: ApiURLs.baseURL,
			headers: headers,
		});

		Object.keys(ApiURLs).forEach((element) => {
			if ('baseURL' !== element) {
				Service.prototype[element] = function (payload) {
					let validPayload = true;
					if (undefined !== ApiURLs[element].requires)
						// Body params Required
						ApiURLs[element].requires.forEach((elementPayload) => {
							validPayload =
								validPayload &&
								payload.hasOwnProperty(elementPayload);
						});
					if (undefined !== ApiURLs[element].urlParams)
						// URL Parameters Required
						ApiURLs[element].urlParams.forEach((elementPayload) => {
							validPayload =
								validPayload &&
								payload.urlParams?.hasOwnProperty(
									elementPayload
								);
						});
					if (undefined !== ApiURLs[element].params)
						// URL Parameters Required
						ApiURLs[element].params.forEach((elementPayload) => {
							validPayload =
								validPayload &&
								payload.params?.hasOwnProperty(elementPayload);
						});
					if (!validPayload)
						return Promise.reject({
							message: 'Not a valid request',
							error: true,
						});
					//console.log('this.service', this.service);
					return this[ApiURLs[element].method](
						ApiURLs[element].href,
						payload
					);
				};
			}
		});

		this.headers = headers;
		this.service = service;
		this.service.interceptors.response.use(
			this.handleSuccess,
			this.handleError
		);

		this.service.interceptors.request.use(this.handleRequest, (error) => {
			return Promise.reject(error);
		});
	}

	handleRequest(config) {
		if (!config.url) {
			return config;
		}
		const currentUrl = new URL(config.url, config.baseURL);
		// parse pathName to implement variables
		Object.entries(config.urlParams || {}).forEach(([key, value]) => {
			currentUrl.pathname = currentUrl.pathname.replace(
				`:${key}`,
				encodeURIComponent(value)
			);
		});
		console.log(config);

		return {
			...config,
			url: currentUrl.pathname,
		};
	}

	checkAuth() {
		console.log('check auth...');
		if (!this.headers.Authorization) {
			console.log('no hay Authorization');
			if (localStorage.userInfo && localStorage.access_token) {
				console.log('seteamos authorization');
				this.headers.Authorization = `Bearer ${localStorage.userInfo.access_token}`;
			}
		}
	}

	handleSuccess(response) {
		return response;
	}

	handleError = (error) => {
		if (error.response) {
			// Request made and server responded
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			switch (error.response.status) {
				case 401:
					localStorage.removeItem('userInfo');
					window.location.href = '/';
					break;
				/*case 404:
			this.redirectTo(document, '/404')
			break;*/
				default:
					//this.redirectTo(document, '/');
					break;
			}
		} else if (error.request) {
			// The request was made but no response was received
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		}
		return Promise.reject(error);
	};

	redirectTo = (document, path) => {
		document.location = path;
	};

	get(path, params, callback) {
		return this.service.get(path, params);
	}

	patch(path, payload, callback) {
		return this.service.request({
			method: 'PATCH',
			url: path,
			responseType: 'json',
			data: payload,
		}); //.then((response) => callback(response.status, response.data));
	}

	post(path, payload, callback) {
		return this.service.request({
			method: 'POST',
			url: path,
			responseType: 'json',
			data: payload,
		});
	}
}

export default new Service();
