import React, { useEffect } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

export default function Toast(props) {
	useEffect(() => {
		const toastTrigger = document.getElementById(props?.buttonID);
		const toastLiveExample = document.getElementById(props?.toastID);
		if (toastTrigger) {
			toastTrigger.addEventListener('click', function () {
				const toast = new bootstrap.Toast(toastLiveExample);

				toast.show();
			});
		}
	}, [props.buttonID, props.toastID]);
	return (
		<div className='position-fixed bottom-0 end-0 p-3' style={{ zIndex: 50000 }}>
			<div
				id={props?.toastID}
				className={`toast align-items-center text-white bg-${props?.variant} border-0`}
				role='alert'
				aria-live='assertive'
				aria-atomic='true'>
				<div className='d-flex'>
					<div className='toast-body'>{props?.children}</div>
					<button
						type='button'
						className='btn-close btn-close-white me-2 m-auto'
						data-bs-dismiss='toast'
						aria-label='Close'></button>
				</div>
			</div>
		</div>
	);
}
