import axios from "axios";

export const BASE_URL = "https://dsgbiblioteca.dsguatemala.edu.gt/api";

export const glAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  timeout: 10000,
});

export const glAxiosRequest = async (method, endpoint, payload) => {
  try {
    const response = await glAxios[method](endpoint, payload);
    return {...response.data, success: true};
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return { success: false, status: error?.response?.status, message: message };
  }
}